import { createTheme } from '@material-ui/core';
import '@fontsource/inter/variable-full.css';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    darkerPrimary: string;
    flat: {
      NYCTaxi: string;
      algalFuel: string;
      maximumBlueGreen: string;
      blueGrey: string;
      boyzone: string;
      desire: string;
    };
  }
  interface PaletteOptions {
    darkerPrimary?: string;
    flat?: {
      NYCTaxi: string;
      algalFuel: string;
      maximumBlueGreen: string;
      blueGrey: string;
      boyzone: string;
      desire: string;
    };
  }
  interface TypeBackground {
    default: string;
    paper: string;
    contrast: string;
  }
}

const spacing = 8;

export default createTheme({
  spacing,
  typography: {
    fontFamily: 'InterVariable',
    h1: {
      fontSize: spacing * 4,
      fontWeight: 300,
    },
    subtitle1: {
      fontSize: spacing * 3,
      fontWeight: 300,
    },
    subtitle2: {
      fontSize: spacing * 2,
      fontWeight: 300,
    },
    body1: {
      fontSize: spacing * 1.75,
      fontWeight: 300,
    },
    body2: {
      fontSize: spacing * 1.5,
      fontWeight: 300,
    },
    fontWeightBold: 500,
    fontWeightLight: 300,
    fontSize: spacing * 2,
  },
  palette: {
    mode: 'light',
    flat: {
      NYCTaxi: '#f7b731',
      algalFuel: '#20bf6b',
      maximumBlueGreen: '#2bcbba',
      boyzone: '#2d98da',
      blueGrey: '#778ca3',
      desire: '#eb3b5a',
    },
    primary: {
      main: '#4C04DB',
    },
    darkerPrimary: '#230067',
    secondary: {
      main: '#FA8231',
    },
    background: {
      default: '#F5F5F5',
      paper: '#FFFFFF',
      contrast: '#FCFCFC',
    },
    grey: {
      200: '#EEEEEE',
      300: '#E6E6E6',
      500: '#A8A8A8',
    },
    text: {
      primary: '#707070',
      secondary: '#777777',
    },
    divider: '#eeeeee',
    error: {
      main: '#EC3B59',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '50vh',
        },
        input: {
          paddingLeft: '2em',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 0,
          fontWeight: 300,
          textTransform: 'none',
          borderRadius: '50vh',
        },
      },
    },
  },
});
