import { Button } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { SxProps } from '@material-ui/system';
import { Theme } from '@material-ui/core/styles';

export default function DenseButton({ to, href, title, search, children, color, onClick, disabled, sx }: PropsWithChildren<{ href?: string; sx?: SxProps<Theme>, title?: string; onClick?: () => void; to?: string; search?: { key: string, value:string }[]; disabled?: boolean; color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'; }>): JSX.Element {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  (search ?? []).forEach(({ key, value }) => urlSearchParams.append(key, value));
  return onClick ? <Button href={href} title={title} disabled={disabled} color={color} variant="outlined" sx={{ pt: 0, pb: 0, ...sx }} onClick={onClick}>{children}</Button> : <Button href={href} title={title} disabled={disabled} component={NavLink} color={color} variant="outlined" sx={{ pt: 0, pb: 0, ...sx }} to={`${to ?? location.pathname}?${urlSearchParams.toString()}`}>{children}</Button>;
}
