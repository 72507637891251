import { Box } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { SxProps, SystemProps } from '@material-ui/system';
import { isEqual } from 'lodash';
import React, { memo, ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../ErrorFallback';
import Loading from '../Loading';
import Breadcrumbs from './Breadcrumbs';
import ContainerBody from './ContainerBody';
import ContainerTitle from './ContainerTitle';

type ContainerChildren =
  | ReactElement<ContainerBody>
  | [ReactElement<ContainerTitle>, ReactElement<ContainerBody>]
  | [ReactElement<ContainerTitle>, ReactElement<Breadcrumbs>, ReactElement<ContainerBody>];

type BodyContainerProps = SystemProps<Theme> & {
  sx?: SxProps<Theme>;
  children: ContainerChildren;
};

function BodyContainer({
  children,
  ...props
}: BodyContainerProps & {
  children: ContainerChildren;
}): JSX.Element {
  return (
    <Box {...props} sx={{ display: 'flex', flex: 1, flexDirection: 'column', ...props.sx }}>
      <ErrorBoundary fallbackRender={ErrorFallback}>
        <React.Suspense fallback={<Loading />}>
          {children}
        </React.Suspense>
      </ErrorBoundary>
    </Box>
  );
}

export default memo(BodyContainer, isEqual);
