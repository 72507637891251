import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TextField, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { SxProps, SystemProps } from '@material-ui/system';
import React from 'react';
import { InputBaseProps } from '@material-ui/core/InputBase';

interface LoginInputProps {
  label?: string;
  placeholder?: string;
  icon?: IconProp;
  otherIcon?: JSX.Element;
  required?: boolean;
  containerProps?: SystemProps<Theme> & {
    sx?: SxProps<Theme>;
  };
  textFieldProps?: TextFieldProps;
  inputProps?: InputBaseProps['inputProps'];
}

function IconInput({
  label, placeholder, icon, otherIcon, containerProps, textFieldProps, inputProps,
}: LoginInputProps) {
  return (
    <Box {...containerProps}>
      {label && (
        <Typography variant="body2" pl={2} sx={{ color: (t) => (textFieldProps?.error ? t.palette.error.main : t.palette.primary.main), paddingBottom: (t) => t.spacing(1) }}>
          {label}
        </Typography>
      )}
      <TextField
        {...textFieldProps}
        fullWidth
        placeholder={label || (placeholder ?? '')}
        variant="outlined"
        inputProps={{
          ...inputProps,
          ...textFieldProps?.inputProps,
        }}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        InputProps={{
          sx: { color: (t: any) => t.palette.text.primary },
          startAdornment: icon ? (
            <Box pl={1} sx={{ fontSize: (t) => t.spacing(3), color: (t) => t.palette.text.disabled }}>
              <FontAwesomeIcon icon={icon} />
            </Box>
          ) : otherIcon,
        }}
      />
    </Box>
  );
}

export default IconInput;
