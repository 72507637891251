import { useCallback, useState } from 'react';
import {
  atomFamily,
  RecoilState,
  selectorFamily,
} from 'recoil';
// eslint-disable-next-line import/no-cycle
import { jwtTokenQuery, useJwtToken } from './Authentication';

type SDKResult<T> = T extends ({ statusCode: 201, result: infer X }) ? X : T extends ({ statusCode: 200, result: infer X }) ? X : never;

export function sdkSelector<SDK, FN extends keyof SDK>(key: string, sdk: (jwt: string) => SDK, call: FN): SDK[FN] extends ((...args: any) => any) ? { selector: (param: Parameters<SDK[FN]>) => RecoilState<SDKResult<Awaited<ReturnType<SDK[FN]>>>>, atom: (param: Parameters<SDK[FN]>) => RecoilState<SDKResult<Awaited<ReturnType<SDK[FN]>>>> } : never {
  const selectorForSdk = selectorFamily<any, any>({
    key,
    get: (params) => async ({ get }: any) => {
      const jwtToken = get(jwtTokenQuery);
      if (jwtToken) {
        const response = await (sdk(jwtToken)[call] as any)(...params);
        return response.statusCode >= 200 && response.statusCode <= 201 ? response.result : undefined;
      }
      return undefined;
    },
  });
  return {
    selector: selectorForSdk,
    atom: atomFamily({ key: `${key}State`, default: selectorForSdk }),
  } as any;
}

export function sdkMultiSelector<SDK, FN extends keyof SDK>(key: string, sdk: (jwt: string) => SDK, call: FN): SDK[FN] extends ((...args: any) => any) ? { selector: (param: Parameters<SDK[FN]>[]) => RecoilState<SDKResult<Awaited<ReturnType<SDK[FN]>>>[]>, atom: (param: Parameters<SDK[FN]>[]) => RecoilState<{ input: Parameters<SDK[FN]>, output: SDKResult<Awaited<ReturnType<SDK[FN]>>> }[]> } : never {
  const selectorForSdk = selectorFamily<any, any>({
    key,
    get: (params) => async ({ get }: any) => {
      const jwtToken = get(jwtTokenQuery);
      if (jwtToken && params.length) {
        const responses = await Promise.all(params.map((it: any[]) => (sdk(jwtToken)[call] as any)(...it)));
        if (responses.some((response) => !(response.statusCode >= 200 && response.statusCode <= 201))) {
          return undefined;
        }
        return params.map((input: any[], index: number) => ({ input, output: responses[index].result }));
      }
      return undefined;
    },
  });
  return {
    selector: selectorForSdk,
    atom: atomFamily({ key: `${key}State`, default: selectorForSdk }),
  } as any;
}

interface CallStatus<T> {
  loading: boolean;
  failed: boolean;
  succeeded: boolean;
  result?: T;
}

export function useCall<SDK, FN extends keyof SDK>(sdk: (jwt: string) => SDK, call: FN, onComplete?: SDK[FN] extends ((...args: any) => any) ? (result: SDKResult<Awaited<ReturnType<SDK[FN]>>>, ...params: Parameters<SDK[FN]>) => void : () => void): SDK[FN] extends ((...args: any) => any) ? { status: CallStatus<SDKResult<Awaited<ReturnType<SDK[FN]>>>>, reset: () => void, trigger: (...params: Parameters<SDK[FN]>) => Promise<SDKResult<Awaited<ReturnType<SDK[FN]>>>> }: never {
  const jwt = useJwtToken();
  const [status, setStatus] = useState<CallStatus<any>>({ loading: false, failed: false, succeeded: false, result: undefined });
  const trigger = useCallback(async (...params: any) => {
    setStatus({ loading: true, succeeded: false, failed: false });
    try {
      const result = await ((sdk(jwt)[call] as any)(...params));
      if (result.statusCode >= 200 && result.statusCode <= 201) {
        setStatus({ loading: false, succeeded: true, failed: false, result: result.result });
        try {
          onComplete?.(result.result, ...params);
        } catch (e) {
          // do nothing
        }
      } else {
        setStatus({ loading: false, succeeded: false, failed: true });
      }
      return result.result;
    } catch (e) {
      setStatus({ loading: false, succeeded: false, failed: true });
    }
    return undefined;
  }, [jwt]);
  return {
    status,
    trigger,
    reset: () => setStatus({ loading: false, failed: false, succeeded: false }),
  } as any;
}
