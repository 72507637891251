import { atomFamily, useRecoilState } from 'recoil';

type CookieState = [string | undefined, (value: string) => void];

function getCookie(key: string) {
  return document.cookie.split('; ').reduce((total, currentCookie) => {
    const item = currentCookie.split('=');
    const storedKey = item[0];
    const storedValue = item[1];
    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, '');
}

function setCookie(key: string, value: string | undefined, numberOfDays: number) {
  const now = new Date();
  now.setTime(now.getTime() + (numberOfDays * 60 * 60 * 24 * 1000));
  document.cookie = `${key}=${value ?? ''}; expires=${now.toUTCString()}; path=/`;
}

function clearCookies() {
  document.cookie.split(';').forEach((cookie) => {
    document.cookie = `${cookie.trim().split('=')[0]}=;Path=/;Domain=.klouds.io;Max-Age=0`;
  });
}

const cookieState = atomFamily<string | undefined, string>({
  key: 'cookieState',
  default: (key) => (getCookie(key) !== '' ? getCookie(key) : undefined),
});

export function useConsentCookie(): CookieState {
  const [cookie, setCookieState] = useRecoilState(cookieState('consent'));
  const updateCookie = (value: string) => {
    setCookieState(value);
    if (value === 'accepted') {
      setCookie('consent', value, 356);
    }
    if (value === 'rejected') {
      clearCookies();
    }
  };
  return [cookie, updateCookie];
}
