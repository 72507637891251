import { defineEnvironmentFromProcess } from '@hexlabs/env-vars-ts';
import { CognitoUserPool } from 'amazon-cognito-identity-js';

const { environment: env } = defineEnvironmentFromProcess(
  ['REACT_APP_API_ENDPOINT', 'REACT_APP_USER_POOL_ID', 'REACT_APP_CLIENT_ID', 'REACT_APP_WEBSOCKET_ENDPOINT', 'REACT_APP_REDIRECT_URL', 'REACT_APP_TRACKING_ID', 'REACT_APP_INTERCOM_APP_ID'] as const,
  ['REACT_APP_ENV', 'REACT_APP_AUTH_DOMAIN'] as const,
  {
    REACT_APP_API_ENDPOINT: 'https://api.dev.klouds.io',
    REACT_APP_USER_POOL_ID: 'local_user_pool',
    REACT_APP_CLIENT_ID: 'local',
    REACT_APP_INTERCOM_APP_ID: 'local',
    REACT_APP_WEBSOCKET_ENDPOINT: 'wss://localhost:3001/ws',
    REACT_APP_REDIRECT_URL: 'http://localhost:3000/login',
    REACT_APP_TRACKING_ID: 'G-4QCTSS6DP7',
  },
);

export const environment = env.REACT_APP_ENV ?? 'local';
export const apiEndpoint = env.REACT_APP_API_ENDPOINT;
export const webSocketEndpoint = env.REACT_APP_WEBSOCKET_ENDPOINT;
export const redirectUrl = env.REACT_APP_REDIRECT_URL;
export const trackingId = env.REACT_APP_TRACKING_ID;
export const authEndpoint = env.REACT_APP_AUTH_DOMAIN ? `https://${env.REACT_APP_AUTH_DOMAIN}.auth.eu-west-1.amazoncognito.com` : 'http://localhost:3001';
export const userPool = new CognitoUserPool({
  UserPoolId: env.REACT_APP_USER_POOL_ID,
  ClientId: env.REACT_APP_CLIENT_ID,
  endpoint: env.REACT_APP_ENV ? undefined : 'http://localhost:3001/auth',
});
export const intercomAppId = env.REACT_APP_INTERCOM_APP_ID;
