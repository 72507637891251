import { ServicesApiSdk } from '@hexlabsio/klouds-services-sdk';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { atomFamily, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useEffect, useRef } from 'react';
import { AuthService } from '@hexlabsio/klouds-auth-sdk';
import { apiEndpoint } from '../Environment';
import { sdkSelector } from './sdk';

export const unauthenticatedAxios = (url: string) => ({
  call: async (method: any, resource: any, path: string, body: any, pathParameters: any, queryParameters: any, multiQueryParameters: any, headers: any) => {
    const result = await axios(url + path, {
      method: method as any,
      data: body,
      params: { ...queryParameters, ...multiQueryParameters },
      headers,
      transformResponse: [],
    });
    return {
      statusCode: result.status,
      body: result.data,
      headers: result.headers as any,
    };
  },
});

export const authenticatedAxios = (jwtToken: string, url?: string) => ({
  call: async (method: any, resource: any, path: string, body: any, pathParameters: any, queryParameters: any, multiQueryParameters: any, headers: any) => {
    const updatedHeaders = {
      ...headers,
      Authorization: `Bearer ${jwtToken}`,
      'X-Session-Id': CryptoJS.MD5(jwtToken).toString(),
      'X-Correlation-Id': uuid(),
    };
    const result = await axios(url + path, {
      method: method as any,
      data: body,
      paramsSerializer: (params) => qs.stringify(params),
      params: { ...queryParameters, ...multiQueryParameters },
      headers: updatedHeaders,
      transformResponse: [],
      validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
    });
    return {
      statusCode: result.status,
      body: result.data,
      headers: result.headers as any,
    };
  },
});

const getServicesSdk = (jwtToken: string) => new ServicesApiSdk(authenticatedAxios(jwtToken, `${apiEndpoint}/services`));

export type RequestType = 'Connections';

export const resetter = atomFamily<number, RequestType>({
  key: 'globalRequestResetter',
  default: () => 0,
});

export const useDidMountEffect = (func: () => void, deps: React.DependencyList | undefined) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export function useNavigateWithQuery() {
  const navigate = useNavigate();
  const location = useLocation();
  return (to: string) => {
    if (to.includes('?')) {
      navigate({ pathname: to.substring(0, to.indexOf('?')), search: location.search ? `${location.search}&${to.substring(to.indexOf('?') + 1)}` : to.substring(to.indexOf('?') + 1) });
    } else navigate({ pathname: to, search: location.search });
  };
}

const { atom: categoriesRequestState } = sdkSelector('categoriesRequest', getServicesSdk, 'getCategories');
const { atom: servicesRequestState } = sdkSelector('servicesRequest', getServicesSdk, 'getServices');
const { atom: regionsRequestState } = sdkSelector('regionsRequest', getServicesSdk, 'getRegions');

export const useAwsCategories = () => useRecoilValue(categoriesRequestState([])).member.filter((it) => it.name !== 'Unknown');
export const useLoadableAwsCategories = () => useRecoilValueLoadable(categoriesRequestState([]));
export const useLoadableAwsServices = () => useRecoilValueLoadable(servicesRequestState([]));
export const useAwsServices = () => useRecoilValue(servicesRequestState([]));
export const useLoadableAwsRegions = () => useRecoilValueLoadable(regionsRequestState([]));
export const useAwsRegions = () => useRecoilValue(regionsRequestState([]));

const useUnauthenticatedAuthSdk = () => new AuthService(unauthenticatedAxios(`${apiEndpoint}/auth-public`), {});

export const useCreateUserHandshake = (setErrors: Function) => {
  const unauthenticatedAuthSdk = useUnauthenticatedAuthSdk();

  return async (email: string, organisation: string) => {
    try {
      await unauthenticatedAuthSdk.createUserHandshake({ email, organisation });
    } catch (error) {
      setErrors([error]);
    }
  };
};
