import { ReportRunCollection } from '@hexlabsio/klouds-reports-sdk';
import { Table, TableCell, TableContainer, TableHead, TableRow, Theme } from '@material-ui/core';
import { SxProps } from '@material-ui/system';
import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useUrlSingleState } from '../../../services/QueryString';
import { useReportRun } from '../../../services/Reports';

function RunRows({ runs }: { runs: ReportRunCollection['member'] }) {
  if (runs.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={7} sx={{ textAlign: 'center' }}>No runs yet</TableCell>
      </TableRow>
    );
  }
  return (
    <>
      { runs.map((run, index) => {
        const cellProps: SxProps<Theme> = { textAlign: 'center', backgroundColor: t => (index % 2 === 0 ? t.palette.background.contrast : t.palette.background.paper) };
        return (
          <TableRow>
            <TableCell sx={cellProps}>{moment(run.reportFromDate).format('LLL')}</TableCell>
            <TableCell sx={cellProps}>{moment(run.reportRunDate).format('LLL')}</TableCell>
            <TableCell sx={cellProps}>{run.cadence}</TableCell>
            <TableCell sx={cellProps}> </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}

export default function ReportRunTable() {
  const { pathname } = useLocation();
  const [organisation] = useUrlSingleState('organisation');
  const runs = useReportRun(organisation ?? '', pathname.substring(pathname.lastIndexOf('/') + 1));
  const commonCellProps: SxProps<Theme> = { textAlign: 'center' };
  return (
    <TableContainer sx={{ mt: 2, borderRadius: '20px', pb: 2, backgroundColor: t => t.palette.background.paper }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={commonCellProps}>Report Date</TableCell>
            <TableCell sx={commonCellProps}>Report Run Time</TableCell>
            <TableCell sx={commonCellProps}>Cadence</TableCell>
            <TableCell sx={commonCellProps}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <RunRows runs={runs as any} />
      </Table>
    </TableContainer>
  );
}
