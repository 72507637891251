import { IntegrationServiceApiSdk, IntegrationCreated } from '@hexlabsio/klouds-integrations-sdk';
import { useState } from 'react';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { apiEndpoint } from '../Environment';
import { sdkSelector, useCall } from './sdk';
import { authenticatedAxios } from './Services';

const getIntegrationsSdk = (jwtToken: string) => new IntegrationServiceApiSdk(authenticatedAxios(jwtToken, `${apiEndpoint}/integrations`));
export const { atom: emailIntegrationsState } = sdkSelector('emailIntegrationsState', getIntegrationsSdk, 'getEmailIntegrations');
export const { atom: slackIntegrationsState } = sdkSelector('slackIntegrationsState', getIntegrationsSdk, 'getSlackIntegrations');
export const { atom: linkedIntegrationsState } = sdkSelector('linkedIntegrationsState', getIntegrationsSdk, 'getLinkedIntegrations');

export function useEmailIntegrations(organisation: string) {
  return [...useRecoilValue(emailIntegrationsState([{ organisation }])).member].sort((a, b) => b.created.localeCompare(a.created));
}

export function useSlackIntegrations(organisation: string) {
  return [...useRecoilValue(slackIntegrationsState([{ organisation }])).member].sort((a, b) => b.created.localeCompare(a.created));
}

export function useLinkedIntegrations(organisation: string, integratedType: string, integrationType: string) {
  return [...useRecoilValue(linkedIntegrationsState([{ organisation }, { integratedType, integrationType }, {}])).member].sort((a, b) => b.integration.created.localeCompare(a.integration.created));
}

export function useCreateEmailIntegration(onSuccess: (result: IntegrationCreated) => void) {
  return useCall(getIntegrationsSdk, 'createEmailIntegration', onSuccess);
}

export function useVerifyEmail() {
  return useCall(getIntegrationsSdk, 'verifyEmail');
}

export function useLinkIntegration() {
  return useCall(getIntegrationsSdk, 'createLinkedIntegrations');
}

export function useLinkIntegrations() {
  const { trigger } = useLinkIntegration();
  const [status, setStatus] = useState({ loading: false, failed: false, succeeded: false });

  return {
    trigger: async (organisation: string, integrationIdentifiers: string[], integratedType: string, integratedIdentifier: string) => {
      setStatus({ loading: true, failed: false, succeeded: false });
      try {
        for (let i = 0; i < integrationIdentifiers.length; i += 1) {
          await trigger({ organisation }, {
            integratedType,
            integrationIdentifier: integrationIdentifiers[i],
            integratedIdentifier,
          });
        }
        setStatus({ loading: false, failed: false, succeeded: true });
      } catch (e) {
        // do nothing
      }
    },
    status,
  };
}

export function useVerifySlack() {
  return useCall(getIntegrationsSdk, 'createSlackIntegration');
}

export function useResendCode() {
  return useCall(getIntegrationsSdk, 'resendCode');
}

export function useResetIntegrations(organisation: string) {
  return useRecoilRefresher_UNSTABLE(emailIntegrationsState([{ organisation }]));
}

export function useResetSlackIntegrations(organisation: string) {
  return useRecoilRefresher_UNSTABLE(slackIntegrationsState([{ organisation }]));
}
