import { AuthServicePrivateApiSdk } from '@hexlabsio/klouds-auth-sdk';
import { Connection } from '@hexlabsio/klouds-connections-sdk';
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';
import { apiEndpoint } from '../Environment';
import { connectionsState, useConnections } from './Connection';
import { sdkSelector, useCall } from './sdk';
import { authenticatedAxios } from './Services';
import { useUser } from './Authentication';

const getAuthSdk = (jwtToken: string) => new AuthServicePrivateApiSdk(authenticatedAxios(jwtToken, `${apiEndpoint}/auth`));

const { atom: userAccessRequestState } = sdkSelector('userAccessRequest', getAuthSdk, 'getUserAccessAll');

export function useConnectionAccess() {
  return useRecoilValue(userAccessRequestState([{ accessTo: 'connection' }]));
}

const { atom: organisationsRequestState } = sdkSelector('organisationsRequest', getAuthSdk, 'getOrgs');
const { atom: organisationRequestState } = sdkSelector('organisationRequest', getAuthSdk, 'getOrg');
const { atom: organisationUsersRequestState } = sdkSelector('organisationUsersRequest', getAuthSdk, 'getOrgUsers');
const { atom: organisationTeamsRequestState } = sdkSelector('organisationTeamsRequest', getAuthSdk, 'getGroups');
const { atom: organisationTeamMembersRequestState } = sdkSelector('organisationTeamMembersRequest', getAuthSdk, 'getGroupUsers');
const { atom: organisationTeamRequestState } = sdkSelector('organisationTeamRequest', getAuthSdk, 'getGroup');
const { atom: organisationTeamInvitesRequestState } = sdkSelector('organisationTeamInvitesRequest', getAuthSdk, 'getGroupInvites');
const { atom: organisationInvitesRequestState } = sdkSelector('organisationInvitesRequest', getAuthSdk, 'getOrgInvites');
const { atom: limitsRequestState } = sdkSelector('limitsRequest', getAuthSdk, 'getLimits');

export const useTeamInvites = (organisation: string, team: string) => useRecoilValue(organisationTeamInvitesRequestState([{ orgId: organisation, groupId: team }]));
export const useOrgInvites = (organisation: string) => useRecoilValue(organisationInvitesRequestState([{ orgId: organisation }]));
export const useTeamInvitesLoadable = (organisation: string, team: string) => useRecoilValueLoadable(organisationTeamInvitesRequestState([{ orgId: organisation, groupId: team }]));
export const useResetTeamInvites = (organisation: string, team: string) => useRecoilRefresher_UNSTABLE(organisationTeamInvitesRequestState([{ orgId: organisation, groupId: team }]));
export const useResetOrgInvites = (organisation: string) => useRecoilRefresher_UNSTABLE(organisationInvitesRequestState([{ orgId: organisation }]));
export const useResetOrgUsers = (organisation: string) => useRecoilRefresher_UNSTABLE(organisationUsersRequestState([{ orgId: organisation }, {}, {}]));

export const useOrganisation = (organisation: string) => useRecoilValue(organisationRequestState([{ orgId: organisation }]));
export const useOrganisations = () => useRecoilValue(organisationsRequestState([]));
export const useOrganisationsLoadable = () => useRecoilValueLoadable(organisationsRequestState([]));
export const useLoadingOrganisations = () => useRecoilValueLoadable(organisationsRequestState([]));
export const useResetOrganisations = () => useRecoilRefresher_UNSTABLE(organisationsRequestState([]));

export const useLimitsLoadable = () => useRecoilValueLoadable(limitsRequestState([]));
export const useResetLimits = () => useRecoilRefresher_UNSTABLE(limitsRequestState([]));

export const useOrganisationUsers = (organisation: string, next?: string) => useRecoilValue(organisationUsersRequestState([{ orgId: organisation }, { next }, {}]));
export function useOrgRole(organisation: string): string | undefined {
  const user = useUser();
  const orgUsers = useOrganisationUsers(organisation);
  if (user) {
    return orgUsers.member.find((it) => it.user === user?.email)?.role;
  }
  return undefined;
}
export const useOrganisationUsersLoadable = (organisation: string, next?: string) => useRecoilValueLoadable(organisationUsersRequestState([{ orgId: organisation }, { next }, {}]));
export const useOrganisationTeam = (organisation: string, team: string) => useRecoilValue(organisationTeamRequestState([{ orgId: organisation, groupId: team }]));
export const useOrganisationTeams = (organisation: string) => useRecoilValue(organisationTeamsRequestState([{ orgId: organisation }]));
export const useResetOrganisationTeams = (organisation: string) => useRecoilRefresher_UNSTABLE(organisationTeamsRequestState([{ orgId: organisation }]));
export const useOrganisationTeamMembers = (organisation: string, team: string, next?: string) => useRecoilValue(organisationTeamMembersRequestState([{ orgId: organisation, groupId: team }, { next }, {}]));
export const useOrganisationTeamMembersLoadable = (organisation: string, team: string, next?: string) => useRecoilValueLoadable(organisationTeamMembersRequestState([{ orgId: organisation, groupId: team }, { next }, {}]));
export function useOrganisationRole(organisation: string): string | undefined {
  const user = useUser();
  const orgUsers = useOrganisationUsers(organisation);
  if (user && orgUsers.member) {
    return orgUsers.member.find((it) => it.user === user.email)?.role;
  }
  return undefined;
}

export const useCreateOrg = () => useCall(getAuthSdk, 'createOrg');
export const useInviteToTeam = () => useCall(getAuthSdk, 'createGroupInvite');
export const useInviteToOrg = () => useCall(getAuthSdk, 'createOrgInvite');
export const useCancelTeamInvite = () => useCall(getAuthSdk, 'deleteGroupInvite');
export const useCancelOrgInvite = () => useCall(getAuthSdk, 'deleteOrgInvite');
export const useRemoveUserFromOrg = () => useCall(getAuthSdk, 'deleteOrgUser');
export const useCreateTeam = () => useCall(getAuthSdk, 'createGroup');
export const useDeleteTeam = () => useCall(getAuthSdk, 'deleteGroup');
export const useUpdateTeam = () => useCall(getAuthSdk, 'updateGroup');
export const useDeleteUser = () => useCall(getAuthSdk, 'deleteUser');

export function useConnectionsInTeam(organisation: string, team: string): Connection[] {
  const access = useConnectionAccess();
  const connectionIds = access.map((it) => it.identifier);
  const connections = useConnections();
  const connectionsInOrg = connections.member.filter((it) => it.organisation === organisation && connectionIds.includes(it.identifier));
  return connectionsInOrg?.filter((it) => {
    const matchingAccess = access.find((accessToIt) => accessToIt.identifier === it.identifier);
    return !!matchingAccess?.orgs.find((org) => org.identifier === organisation)?.groups?.find((group) => group.identifier === team);
  }) ?? [];
}

export function useConnectionsInOrgButNotInTeam(organisation: string, team: string): { loading?: boolean; connections?: Connection[] } {
  const accessRequest = useRecoilValueLoadable(userAccessRequestState([{ accessTo: 'connection' }]));
  const connectionRequest = useRecoilValueLoadable(connectionsState([]));
  if (accessRequest.state !== 'hasValue' || connectionRequest.state !== 'hasValue') {
    return { loading: true };
  }
  const access = accessRequest.getValue();
  const connectionIds = access.map((it) => it.identifier);
  const connections = connectionRequest.getValue();
  const connectionsInOrg = connections.member.filter((it) => it.organisation === organisation && connectionIds.includes(it.identifier));
  const notInTeam = connectionsInOrg?.filter((it) => {
    const matchingAccess = access.find((accessToIt) => accessToIt.identifier === it.identifier);
    return !matchingAccess?.orgs.find((org) => org.identifier === organisation)?.groups?.find((group) => group.identifier === team);
  }) ?? [];
  return { connections: notInTeam };
}
