import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import Loading from '../../components/Loading';
import { useOrganisationsLoadable } from '../../services/Organisation';
import { useUrlSingleState } from '../../services/QueryString';

export default function OrgSelection() {
  const [selected, setSelected] = useUrlSingleState('organisation');
  const orgs = useOrganisationsLoadable();
  useEffect(() => {
    if (!selected && orgs.state === 'hasValue') {
      setSelected(orgs.getValue().member[0].identifier);
    }
  }, [orgs, selected]);
  if (orgs.state === 'loading' || !selected) return <Box><Loading /></Box>;
  return (
    <Select variant="standard" defaultValue={selected} onChange={e => { setTimeout(() => setSelected(e.target.value as any), 100); }}>
      { orgs.getValue().member.map(org => <MenuItem value={org.identifier} selected={org.identifier === selected}><Typography variant="subtitle2">{org.name}</Typography></MenuItem>) }
    </Select>
  );
}
