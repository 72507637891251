import { faHashtag } from '@fortawesome/pro-light-svg-icons';
import { VerificationCode } from '@hexlabsio/klouds-integrations-sdk';
import { Box, Button, Container, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import BodyContainer from '../../components/BodyContainer';
import Breadcrumbs from '../../components/BodyContainer/Breadcrumbs';
import ContainerBody from '../../components/BodyContainer/ContainerBody';
import ContainerTitle from '../../components/BodyContainer/ContainerTitle';
import Crumb from '../../components/BodyContainer/Crumb';
import IconInput from '../../components/IconInput';
import InfoAlert from '../../components/InfoAlert';
import Loading from '../../components/Loading';
import { useResetIntegrations, useVerifyEmail } from '../../services/Integrations';
import { useUrlSingleState } from '../../services/QueryString';
import { useNavigateWithQuery } from '../../services/Services';
import OrgSelection from './OrgSelection';

function VerifyForm() {
  const [organisation] = useUrlSingleState('organisation');
  const navigate = useNavigateWithQuery();
  const reset = useResetIntegrations(organisation ?? '');
  const [defaultCode, setDefaultCode] = useUrlSingleState('verifyCode');
  const { handleSubmit, register, formState: { errors }, clearErrors } = useForm<VerificationCode>({ defaultValues: { code: defaultCode } });
  const { trigger, status } = useVerifyEmail();
  const [error, setError] = useState<string | undefined>();
  useEffect(() => {
    if (status.failed) {
      setError('Could not verify email, it may have expired, please try again later');
      setDefaultCode(undefined);
    } else {
      setError(undefined);
      clearErrors();
      if (status.succeeded) {
        reset();
        setDefaultCode(undefined);
        setTimeout(() => navigate('/integrations'), 100);
      }
    }
  }, [status.failed, status.succeeded]);
  const invalidMessage = errors.code?.type === 'required' ? 'This field is required' : '';
  return (
    <Container maxWidth="md">
      <Box sx={{ backgroundColor: t => t.palette.background.paper, borderRadius: '20px' }} p={4}>
        <Box textAlign="center"><Typography variant="subtitle1">Verify Email Address</Typography></Box>
        <Box textAlign="center" color={t => t.palette.error.main}><Typography variant="subtitle2">{error}</Typography></Box>
        <Box pt={2}>
          <form onSubmit={handleSubmit((request) => organisation && trigger({ organisation }, request))}>
            <IconInput label="Verification Code" textFieldProps={{ helperText: invalidMessage, error: !!invalidMessage }} inputProps={{ ...register('code', { required: true }) }} icon={faHashtag} />
            <InfoAlert sx={{ mt: 2 }}>Check your emails for a verification code</InfoAlert>
            <Box pt={2} display="flex">
              <Button variant="outlined" size="large" sx={{ mr: 2 }} onClick={() => navigate('/integrations')}>
                Back
              </Button>
              <Button fullWidth type="submit" variant="outlined" size="large">
                { status.loading && <Box mr={2}><Loading size={24} /></Box> }Verify
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
}

export default function EmailVerify() {
  const location = useLocation();
  return (
    <BodyContainer>
      <ContainerTitle>
        <Typography variant="h1" sx={{ mr: 2 }}>Email Verification</Typography>
      </ContainerTitle>
      <Breadcrumbs>
        <Crumb to={location.pathname}>Integrations for <OrgSelection /></Crumb>
        <Crumb to="/integrations/email/create">Create Email Integration</Crumb>
        <Crumb to={location.pathname}>Verify Email</Crumb>
      </Breadcrumbs>
      <ContainerBody>
        <Helmet>
          <title>Verify Email Integration - klouds.io - Cloud Expenditure Exposed</title>
        </Helmet>
        <VerifyForm />
      </ContainerBody>
    </BodyContainer>
  );
}
