import { faSlack } from '@fortawesome/free-brands-svg-icons';
import { SlackIntegration, SlackLink } from '@hexlabsio/klouds-integrations-sdk';
import { Box, Button, Table, TableCell, TableContainer, TableHead, TableRow, Theme } from '@material-ui/core';
import { SxProps } from '@material-ui/system';
import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlexBox from '../../components/FlexBox';
import { environment } from '../../Environment';
import { useLinkedIntegrations, useSlackIntegrations } from '../../services/Integrations';
import { useUrlSingleState } from '../../services/QueryString';

function IntegrationRows({ integrations, reportIntegrations }: { integrations: SlackIntegration[]; reportIntegrations: SlackLink[] }) {
  if (integrations.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={4} sx={{ textAlign: 'center' }}>No Slack Integrations yet</TableCell>
      </TableRow>
    );
  }
  return (
    <>
      { integrations.map((integration, index) => {
        const cellProps: SxProps<Theme> = { textAlign: 'center', backgroundColor: t => (index % 2 === 0 ? t.palette.background.contrast : t.palette.background.paper) };
        return (
          <TableRow>
            <TableCell sx={cellProps}>{integration.workspace}</TableCell>
            <TableCell sx={cellProps}>{moment(integration.created).fromNow()}</TableCell>
            <TableCell sx={cellProps}>{reportIntegrations.filter(it => it.integration.identifier === integration.identifier).length}</TableCell>
            <TableCell sx={cellProps}>{integration.channel}</TableCell>
          </TableRow>
        );
      })}
    </>
  );
}

export default function SlackIntegrationsTable() {
  const [organisation] = useUrlSingleState('organisation');
  const integrations = useSlackIntegrations(organisation ?? '');
  const linkedIntegrations = useLinkedIntegrations(organisation ?? '', 'report', 'slack');
  const commonCellProps: SxProps<Theme> = { textAlign: 'center' };
  const redirect = environment === 'production' ? 'https://klouds.io' : 'https://dev.klouds.io';
  return (
    <TableContainer sx={{ borderRadius: '20px', pb: 2, backgroundColor: t => t.palette.background.paper }}>
      <FlexBox fontSize={24} pl={4} pt={2}>
        <Box mr={2}><FontAwesomeIcon icon={faSlack} /></Box>
        <Box mr={2}>Slack Integrations</Box>
        <Button color="primary" href={`https://slack.com/oauth/authorize?client_id=434920005014.4058479606114&scope=chat:write:bot links:write channels:read channels:write&redirect_uri=${redirect}/integrations/slack/verify?organisation=${organisation}`} variant="outlined" sx={{ pt: 0, pb: 0 }}>Authorize Workspace</Button>
      </FlexBox>
      <Table>
        { integrations.length ? (
          <TableHead>
            <TableRow>
              <TableCell sx={commonCellProps}>Workspace</TableCell>
              <TableCell sx={commonCellProps}>Created Date</TableCell>
              <TableCell sx={commonCellProps}>Report Integrations</TableCell>
              <TableCell sx={commonCellProps}>Channel</TableCell>
            </TableRow>
          </TableHead>
        ) : undefined}
        <IntegrationRows integrations={integrations} reportIntegrations={linkedIntegrations as SlackLink[]} />
      </Table>
    </TableContainer>
  );
}
