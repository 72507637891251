import React, { Suspense, lazy, memo, useEffect, PropsWithChildren } from 'react';
import isEqual from 'lodash/isEqual';
import { Routes, Route } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import Integrations from '../features/Integrations';
import EmailIntegrationCreate from '../features/Integrations/EmailIntegrationCreate';
import EmailVerify from '../features/Integrations/EmailVerify';
import SlackVerify from '../features/Integrations/SlackVerify';
import ReportRuns from '../features/Reports/Runs';
import { useAnalytics } from '../services/Analytics';
import Loading from './Loading';
import 'react-toastify/dist/ReactToastify.css';

const Landing = lazy(() => import('../features/Landing'));
const Brand = lazy(() => import('./Brand'));
const Login = lazy(() => import('../features/UserManagement/Login'));
const SignUp = lazy(() => import('../features/UserManagement/SignUp'));
const ForgotPassword = lazy(() => import('../features/UserManagement/ForgotPassword'));
const ChangePassword = lazy(() => import('../features/UserManagement/ChangePassword'));
const PrivacyPolicy = lazy(() => import('../features/PrivacyPolicy'));
const ServiceTerms = lazy(() => import('../features/ServiceTerms'));
const Navigation = lazy(() => import('./Navigation'));
const Connections = lazy(() => import('../features/Connections'));
const Insights = lazy(() => import('../features/Insights'));
const Inventory = lazy(() => import('../features/Inventory'));
const Visualise = lazy(() => import('../features/Visualise'));
const CostAnalysis = lazy(() => import('../features/CostAnalysis'));
const Organisations = lazy(() => import('../features/Organisations'));
const CreateOrganisation = lazy(() => import('../features/Organisations/Create'));
const Team = lazy(() => import('../features/Organisations/Team'));
const CreateConnectionAccountType = lazy(() => import('../features/Connections/Create'));
const CreateConnectWithCloudFormation = lazy(() => import('../features/Connections/Create/CreateConnectWithCloudFormation'));
const CreateConnectWithStackSets = lazy(() => import('../features/Connections/Create/CreateConnectWithStackSets'));
const UpdateConnectWithCloudFormation = lazy(() => import('../features/Connections/Update/UpdateConnectWithCloudFormation'));
const ConnectWithCli = lazy(() => import('../features/Connections/Create/ConnectWithCli'));
const Reports = lazy(() => import('../features/Reports'));
const CreateReport = lazy(() => import('../features/Reports/Create'));
const Notifications = lazy(() => import('../features/Notifications'));
const Settings = lazy(() => import('../features/Settings'));
const PlanSelection = lazy(() => import('../features/PlanSelection'));

function RouteWrapper({ children }: PropsWithChildren<{}>) {
  return (
    <Suspense fallback={<Loading />}>
      {children}
    </Suspense>
  );
}
function RouteList() {
  useAnalytics();
  useEffect(() => {
    moment.updateLocale('en', { week: { dow: 1 } });
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <ToastContainer theme="light" autoClose={5000} />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route element={<Brand />}>
          <Route path="/login" element={<RouteWrapper><Login /></RouteWrapper>} />
          <Route path="/create-account" element={<RouteWrapper><SignUp /></RouteWrapper>} />
          <Route path="/forgot-password" element={<RouteWrapper><ForgotPassword /></RouteWrapper>} />
          <Route path="/change-password" element={<RouteWrapper><ChangePassword /></RouteWrapper>} />
          <Route path="/privacy-policy" element={<RouteWrapper><PrivacyPolicy /></RouteWrapper>} />
          <Route path="/terms-of-service" element={<RouteWrapper><ServiceTerms /></RouteWrapper>} />
        </Route>
        <Route element={<Navigation />}>
          <Route path="/connections" element={<RouteWrapper><Connections /></RouteWrapper>} />
          <Route path="/insights" element={<RouteWrapper><Insights /></RouteWrapper>} />
          <Route path="/inventory" element={<RouteWrapper><Inventory /></RouteWrapper>} />
          <Route path="/visualise" element={<RouteWrapper><Visualise /></RouteWrapper>} />
          <Route path="/cost-analysis" element={<RouteWrapper><CostAnalysis /></RouteWrapper>} />
          <Route path="/organisations" element={<RouteWrapper><Organisations /></RouteWrapper>} />
          <Route path="/organisations/create" element={<RouteWrapper><CreateOrganisation /></RouteWrapper>} />
          <Route path="/organisations/:organisation/teams/:team" element={<RouteWrapper><Team /></RouteWrapper>} />
          <Route path="/organisations/:organisation/connections/create" element={<RouteWrapper><CreateConnectionAccountType /></RouteWrapper>} />
          <Route path="/organisations/:organisation/connections/create/cloudformation" element={<RouteWrapper><CreateConnectWithCloudFormation /></RouteWrapper>} />
          <Route path="/organisations/:organisation/connections/create/stack-set" element={<RouteWrapper><CreateConnectWithStackSets /></RouteWrapper>} />
          <Route path="/organisations/:organisation/connections/update/cloudformation/:connectionId" element={<RouteWrapper><UpdateConnectWithCloudFormation /></RouteWrapper>} />
          <Route path="/organisations/:organisation/connections/create/cli" element={<RouteWrapper><ConnectWithCli /></RouteWrapper>} />
          <Route path="/reports" element={<RouteWrapper><Reports /></RouteWrapper>} />
          <Route path="/reports/create" element={<RouteWrapper><CreateReport /></RouteWrapper>} />
          <Route path="/reports/runs/:reportConfigId" element={<RouteWrapper><ReportRuns /></RouteWrapper>} />
          <Route path="/integrations" element={<RouteWrapper><Integrations /></RouteWrapper>} />
          <Route path="/integrations/email/create" element={<RouteWrapper><EmailIntegrationCreate /></RouteWrapper>} />
          <Route path="/integrations/email/verify/:identifier" element={<RouteWrapper><EmailVerify /></RouteWrapper>} />
          <Route path="/integrations/slack/verify" element={<RouteWrapper><SlackVerify /></RouteWrapper>} />
          <Route path="/notifications" element={<RouteWrapper><Notifications /></RouteWrapper>} />
          <Route path="/settings" element={<RouteWrapper><Settings /></RouteWrapper>} />
          <Route path="/plan-selection" element={<RouteWrapper><PlanSelection /></RouteWrapper>} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default memo(RouteList, isEqual);
