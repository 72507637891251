import { CreateEmailIntegration } from '@hexlabsio/klouds-integrations-sdk';
import { Box, Button, Container, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Gravatar from 'react-gravatar';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import BodyContainer from '../../components/BodyContainer';
import Breadcrumbs from '../../components/BodyContainer/Breadcrumbs';
import ContainerBody from '../../components/BodyContainer/ContainerBody';
import ContainerTitle from '../../components/BodyContainer/ContainerTitle';
import Crumb from '../../components/BodyContainer/Crumb';
import IconInput from '../../components/IconInput';
import Loading from '../../components/Loading';
import { useCreateEmailIntegration, useResetIntegrations } from '../../services/Integrations';
import { useUrlSingleState } from '../../services/QueryString';
import { useNavigateWithQuery } from '../../services/Services';
import OrgSelection from './OrgSelection';

function CreateForm() {
  const navigate = useNavigateWithQuery();
  const [organisation] = useUrlSingleState('organisation');
  const { handleSubmit, register, watch, formState: { errors }, clearErrors } = useForm<CreateEmailIntegration>({ });
  const [result, setResult] = useState<string>();
  const { trigger, status } = useCreateEmailIntegration(res => setResult(res.identifier));
  const [error, setError] = useState<string | undefined>();
  const reset = useResetIntegrations(organisation ?? '');
  const values = watch();
  useEffect(() => {
    if (status.failed) {
      setError('Could not create integration, please try again later');
    } else {
      setError(undefined);
      clearErrors();
    }
    if (result) {
      reset();
      navigate(`/integrations/email/verify/${result}`);
    }
  }, [status.failed, status.succeeded, result]);
  const emailMessage = errors.email?.type === 'required' ? 'This field is required' : errors.email?.type === 'pattern' ? 'This field must be an email address' : '';
  return (
    <Container maxWidth="md">
      <Box sx={{ backgroundColor: t => t.palette.background.paper, borderRadius: '20px' }} p={4}>
        <Box textAlign="center"><Typography variant="subtitle1">Email Integration</Typography></Box>
        <Box textAlign="center" color={t => t.palette.error.main}><Typography variant="subtitle2">{error}</Typography></Box>
        <Box pt={2}>
          <form onSubmit={handleSubmit((request) => organisation && trigger({ organisation }, request))}>
            <IconInput label="Email Address" textFieldProps={{ type: 'email', helperText: emailMessage, error: !!emailMessage }} inputProps={{ ...register('email', { required: true, pattern: /.+@.+/ }) }} otherIcon={<Gravatar email={values.email ?? ''} default="mm" width={32} height={32} style={{ borderRadius: '50vh', marginRight: '1em' }} />} />
            <Box pt={2} display="flex">
              <Button variant="outlined" size="large" sx={{ mr: 2 }} onClick={() => navigate('/integrations')}>
                Back
              </Button>
              <Button fullWidth type="submit" variant="outlined" size="large">
                { status.loading && <Box mr={2}><Loading size={24} /></Box> }Create
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
}

export default function EmailIntegrationCreate() {
  return (
    <BodyContainer>
      <ContainerTitle>
        <Typography variant="h1" sx={{ mr: 2 }}>Create Email Integration</Typography>
      </ContainerTitle>
      <Breadcrumbs>
        <Crumb to="/integrations/email/create">Integrations for <OrgSelection /></Crumb>
        <Crumb to="/integrations/email/create">Create Email Integration</Crumb>
      </Breadcrumbs>
      <ContainerBody>
        <Helmet>
          <title>Create Email Integration - klouds.io - Cloud Expenditure Exposed</title>
        </Helmet>
        <CreateForm />
      </ContainerBody>
    </BodyContainer>
  );
}
