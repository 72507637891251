import { faHashtag } from '@fortawesome/pro-light-svg-icons';
import { CreateSlackIntegration } from '@hexlabsio/klouds-integrations-sdk';
import { Box, Button, Container, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import BodyContainer from '../../components/BodyContainer';
import Breadcrumbs from '../../components/BodyContainer/Breadcrumbs';
import ContainerBody from '../../components/BodyContainer/ContainerBody';
import ContainerTitle from '../../components/BodyContainer/ContainerTitle';
import Crumb from '../../components/BodyContainer/Crumb';
import IconInput from '../../components/IconInput';
import Loading from '../../components/Loading';
import { useResetSlackIntegrations, useVerifySlack } from '../../services/Integrations';
import { useUrlSingleState } from '../../services/QueryString';
import OrgSelection from './OrgSelection';

function VerifyForm() {
  const [organisation] = useUrlSingleState('organisation');
  const [defaultCode, setDefaultCode] = useUrlSingleState('code');
  const navigate = useNavigate();
  const reset = useResetSlackIntegrations(organisation ?? '');
  const { trigger, status } = useVerifySlack();
  const { handleSubmit, register, formState: { errors }, clearErrors } = useForm<CreateSlackIntegration>({ defaultValues: { verifyCode: defaultCode } });
  const [error, setError] = useState<string | undefined>();
  useEffect(() => {
    if (status.failed) {
      setError('Could not verify token from slack, it may have expired, please try again later');
      setDefaultCode(undefined);
    } else {
      setError(undefined);
      clearErrors();
      if (status.succeeded) {
        reset();
        setDefaultCode(undefined);
        setTimeout(() => navigate(`/integrations?organisation=${organisation}`), 100);
      }
    }
  }, [status.failed, status.succeeded]);
  const invalidMessage = errors.channel?.type === 'required' ? 'This field is required' : 'If this doesn\'t exist a new channel will be created';

  return (
    <Container maxWidth="md">
      <Box sx={{ backgroundColor: t => t.palette.background.paper, borderRadius: '20px' }} p={4}>
        <Box textAlign="center"><Typography variant="subtitle1">Create Slack Integration</Typography></Box>
        <Box textAlign="center" color={t => t.palette.error.main}><Typography variant="subtitle2">{error}</Typography></Box>
        <Box pt={2}>
          <form onSubmit={handleSubmit((request) => organisation && trigger({ organisation }, request))}>
            <IconInput label="Slack Channel" textFieldProps={{ helperText: invalidMessage, error: !!errors.channel }} inputProps={{ ...register('channel', { required: true }) }} icon={faHashtag} />
            <Box pt={2} display="flex">
              <Button variant="outlined" size="large" sx={{ mr: 2 }} onClick={() => navigate('/integrations')}>
                Back
              </Button>
              <Button fullWidth type="submit" variant="outlined" size="large">
                { status.loading && <Box mr={2}><Loading size={24} /></Box> }Verify
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
}

export default function SlackVerify() {
  const location = useLocation();
  return (
    <BodyContainer>
      <ContainerTitle>
        <Typography variant="h1" sx={{ mr: 2 }}>Slack Verification</Typography>
      </ContainerTitle>
      <Breadcrumbs>
        <Crumb to={location.pathname}>Integrations for <OrgSelection /></Crumb>
        <Crumb to="/integrations/slack/verify">Verify Slack Integration</Crumb>
      </Breadcrumbs>
      <ContainerBody>
        <Helmet>
          <title>Verify Slack Integration - klouds.io - Cloud Expenditure Exposed</title>
        </Helmet>
        <VerifyForm />
      </ContainerBody>
    </BodyContainer>
  );
}
