import { useNavigate, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import moment, { Moment } from 'moment';

export const useQueryParameter = (name: string) => {
  const { search } = useLocation();
  const [values, setValues] = useState<string[]>(new URLSearchParams(window.location.search).getAll(name));
  useEffect(() => {
    const updated = new URLSearchParams(search).getAll(name);
    if (!isEqual(values, updated)) {
      setValues(updated);
    }
  }, [search]);
  return values;
};

type UrlState = [string[], (value: string, add: boolean) => void, (updated: string[]) => void];
type UrlBoolean = [boolean, (value: boolean) => void];
type UrlDateRange = [[Moment, Moment] | undefined, (value: [Moment, Moment] | undefined) => void];
type SingleUrlState = [string | undefined, (value: string | undefined) => void];

export function useUrlState(name: string): UrlState {
  const navigate = useNavigate();
  const current = useQueryParameter(name);
  const setValues = useCallback(
    (updated: string[]) => {
      const params = new URLSearchParams(window.location.search);
      params.delete(name);
      updated.forEach((value) => params.append(name, value));
      navigate({ search: params.toString() });
    },
    [current],
  );
  const setValue = useCallback(
    (value: string, add: boolean) => {
      const params = new URLSearchParams(window.location.search);
      const previous = params.getAll(name);
      params.delete(name);
      const updated = [...previous.filter((it) => it !== value), ...(add ? [value] : [])];
      updated.forEach((v) => params.append(name, v));
      navigate({ search: params.toString() });
    },
    [current],
  );
  return [current, setValue, setValues];
}

export function useUrlBooleanState(name: string): UrlBoolean {
  const [urlState,, setValues] = useUrlState(name);
  return [urlState[0] === 'true', (value) => setValues(value ? ['true'] : [])];
}

export function useUrlSingleState(name: string): SingleUrlState {
  const [urlState,, setValues] = useUrlState(name);
  return [urlState[0], (value) => setValues(value ? [value] : [])];
}

export function getFormatFrom(granularity: string | undefined) {
  return granularity === 'yearly' ? 'YYYY' : granularity === 'monthly' ? 'YYYY-MM' : 'YYYY-MM-DD';
}

function getUnitOfTimeFrom(granularity: string | undefined) {
  return granularity === 'yearly' ? 'year' : granularity === 'monthly' ? 'month' : 'day';
}

export function useUrlDateRangeState(name: string, initial?: [Moment, Moment]): UrlDateRange {
  const [value, setValue] = useUrlSingleState(name);
  const [granularity] = useUrlSingleState('granularity');
  const [fromQuery, toQuery] = value ? [value.substring(0, value.indexOf('/')), value.substring(value.indexOf('/') + 1, value.length)] : [];
  const from = fromQuery && moment(fromQuery, getFormatFrom(granularity)).isValid() ? moment(fromQuery, getFormatFrom(granularity)).utc(true) : initial && initial[0] ? initial[0] : undefined;
  const to = toQuery && moment(toQuery, getFormatFrom(granularity)).isValid() ? moment(toQuery, getFormatFrom(granularity)).utc(true) : initial && initial[1] ? initial[1] : undefined;
  const currentFrom = from?.startOf(getUnitOfTimeFrom(granularity));
  const currentTo = to?.endOf(getUnitOfTimeFrom(granularity));
  return [currentFrom && currentTo ? [currentFrom, currentTo] : undefined, (updated) => {
    if (updated && updated[0]?.isValid() && updated && updated[1]?.isValid()) {
      setValue(`${updated[0].format(getFormatFrom(granularity))}/${updated[1].format(getFormatFrom(granularity))}`);
    }
    if (!updated) {
      setValue(undefined);
    }
  }];
}

export function useUrlDateRangeValue(name: string, initial?: [Moment, Moment]) {
  const [value] = useUrlDateRangeState(name, initial);
  const [granularity] = useUrlSingleState('granularity');
  return value ? `${value[0].format(getFormatFrom(granularity))}/${value[1].format(getFormatFrom(granularity))}` : undefined;
}

export function useUrlConnectionState(): UrlState {
  return useUrlState('connection');
}

export function useUrlOrganisationState(): SingleUrlState {
  return useUrlSingleState('organisation');
}
