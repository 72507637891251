import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/icons.css';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@material-ui/core';
import { IntercomProvider } from 'react-use-intercom';
import { LocalizationProvider } from '@material-ui/lab';
import MomentUtils from '@date-io/moment';
import theme from './theme';
import RouteList from './components/Routes';
import { intercomAppId } from './Environment';

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={MomentUtils}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <IntercomProvider appId={intercomAppId} autoBoot>
            <RecoilRoot>
              <BrowserRouter>
                <CssBaseline />
                <RouteList />
              </BrowserRouter>
            </RecoilRoot>
          </IntercomProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
