import {
  ConnectionServiceApiSdk,
  HandshakeRequest,
  ConnectionResource,
  Connection,
} from '@hexlabsio/klouds-connections-sdk';
import {
  atom,
  selectorFamily, useRecoilState,
  useRecoilValue, useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { apiEndpoint } from '../Environment';
import { useUrlConnectionState } from './QueryString';
import { sdkSelector, useCall } from './sdk';
import { authenticatedAxios } from './Services';
import { useJwtToken } from './Authentication';

export const getConnectionSdk = (jwtToken: string) => new ConnectionServiceApiSdk(authenticatedAxios(jwtToken, `${apiEndpoint}/connections`));

export const { atom: connectionsState } = sdkSelector('connectionsRequest', getConnectionSdk, 'getConnections');

export function useConnectionUpdate() {
  const setConnections = useSetRecoilState(connectionsState([]));
  return useCall(getConnectionSdk, 'updateConnection', (result, { connectionId }, { alias }) => setConnections((connections) => ({
    ...connections,
    member: connections.member.map((it) => {
      if (it.identifier === connectionId) return { ...it, alias };
      return it;
    }),
  })));
}

export function useDeleteConnection() {
  const setConnections = useSetRecoilState(connectionsState([]));
  return useCall(getConnectionSdk, 'deleteConnection', (result, { connectionId }) => setConnections((connections) => ({
    ...connections,
    member: connections.member.filter((it) => it.identifier !== connectionId),
  }
  )));
}
export interface ConnectionStatus {
  awsAccountId: string;
  organisation: string;
  connectionId?: string;
  parentConnectionId?: string;
}
export const connectionStatusState = atom<{ connectionsStarted: ConnectionStatus[] }>({
  key: 'connectionStatusState',
  default: { connectionsStarted: [] },
});

export function useConnectionStatus() {
  const [status, setStatus] = useRecoilState(connectionStatusState);
  function removeConnection(current: ConnectionStatus[], awsAccountId: string, organisation: string, connectionId?: string, parentConnectionId?: string): ConnectionStatus[] {
    return current.filter(it => !((it.awsAccountId === awsAccountId && it.organisation === organisation && it.parentConnectionId === parentConnectionId) || (it.connectionId === connectionId && connectionId)));
  }
  return {
    status,
    connectionInitiated(awsAccountId: string, organisation: string, connectionId?: string, parentConnectionId?: string) {
      setStatus(old => {
        const removed = removeConnection(old.connectionsStarted, awsAccountId, organisation, connectionId, parentConnectionId);
        return { connectionsStarted: [...removed, { awsAccountId, organisation, connectionId, parentConnectionId }] };
      });
    },
    connectionCreated(awsAccountId: string, organisation: string, connectionId?: string, parentConnectionId?: string) {
      setStatus(old => ({ connectionsStarted: removeConnection(old.connectionsStarted, awsAccountId, organisation, connectionId, parentConnectionId) }));
    },
    connectionFailed(awsAccountId: string, organisation: string) {
      setStatus(old => ({ connectionsStarted: old.connectionsStarted.filter(it => !(it.awsAccountId === awsAccountId && it.organisation === organisation)) }));
    },
  };
}

export default function useHandshake() {
  const jwt = useJwtToken();
  return async (data: HandshakeRequest) => {
    const result = await getConnectionSdk(jwt).createHandshake(data);
    if (result.statusCode === 201) {
      return result.result;
    }
    return undefined;
  };
}

export const connectionRequest = selectorFamily<ConnectionResource | undefined, string>({
  key: 'connectionRequest',
  get: (connectionId) => async ({ get }) => {
    const connections = get(connectionsState([]));
    return connections.member.find((connection) => connection.identifier === connectionId);
  },
});

export const useConnections = () => useRecoilValue(connectionsState([]));
export const useConnectionsLoadable = () => useRecoilValueLoadable(connectionsState([]));
export const useConnection = (connectionId: string) => useRecoilValue(connectionRequest(connectionId));
export const useAddConnectionToTeam = () => useCall(getConnectionSdk, 'addConnectionToTeam');
export const useRemoveConnectionFromTeam = () => useCall(getConnectionSdk, 'removeConnectionFromTeam');

export function useSelectedConnections() {
  const [selected] = useUrlConnectionState();
  const connections = useConnections();
  return (connections ?? []).member.filter((it) => selected.includes(it.identifier));
}

export function useSelectedConnectionsLoadable(): { loading: true; value: undefined } | { loading: false; value: Connection[] } {
  const [selected] = useUrlConnectionState();
  const connections = useRecoilValueLoadable(connectionsState([]));
  if (connections.state === 'hasValue') return { loading: false, value: (connections.getValue() ?? []).member.filter((it) => selected.includes(it.identifier)) };
  return { loading: true, value: undefined };
}
