import { Alert, Box } from '@material-ui/core';
import React from 'react';

function ErrorFallback({ error }: { error: Error; }) {
  const forbidden = error.message === 'Request failed with status code 403';
  return (
    <Box sx={{ flex: 1, alignItems: 'center', padding: '1em' }}>
      <Alert
        sx={{
          flex: 1,
          alignItems: 'center',
          borderRadius: '20px',
          '& .MuiAlert-message': { flex: 1 },
        }}
        severity={forbidden ? 'warning' : 'error'}
      >
        <Box display="flex" alignItems="center">
          <Box flex={1}>{forbidden ? 'Access Denied' : 'Unexpected Error. A refresh may resolve this issue.'}</Box>
        </Box>
      </Alert>
    </Box>
  );
}

export default ErrorFallback;
