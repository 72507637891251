import { KloudsReportServiceApiSdk } from '@hexlabsio/klouds-reports-sdk';
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useSetRecoilState } from 'recoil';
import { apiEndpoint } from '../Environment';
import { sdkSelector, useCall } from './sdk';
import { authenticatedAxios } from './Services';

const getReportsSdk = (jwtToken: string) => new KloudsReportServiceApiSdk(authenticatedAxios(jwtToken, `${apiEndpoint}/reports`));
export const { atom: reportsState } = sdkSelector('reportsState', getReportsSdk, 'getReportConfigs');
export const { atom: reportRunState } = sdkSelector('reportRunState', getReportsSdk, 'getReportRuns');

export function useCreateReport(organisationId: string) {
  const setReports = useSetRecoilState(reportsState([{ organisationId }]));
  const reset = useRecoilRefresher_UNSTABLE(reportsState([{ organisationId }]));
  return useCall(getReportsSdk, 'createReportConfig', result => {
    try {
      setReports(old => ({ ...old, member: [...old.member, result] }));
    } catch (e) {
      reset();
    }
  });
}

export function useReports(organisationId: string) {
  return [...useRecoilValue(reportsState([{ organisationId }])).member].sort((a, b) => b.created.localeCompare(a.created));
}

export function useReportRun(organisationId: string, reportConfigId: string) {
  return useRecoilValue(reportRunState([{ organisationId }, { reportConfigId }, {}]));
}
