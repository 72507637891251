import { faCheck, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { EmailIntegration, EmailLink } from '@hexlabsio/klouds-integrations-sdk';
import { Box, Table, TableCell, TableContainer, TableHead, TableRow, Theme } from '@material-ui/core';
import { SxProps } from '@material-ui/system';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Gravatar from 'react-gravatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import DenseButton from '../../components/DenseButton';
import FlexBox from '../../components/FlexBox';
import Loading from '../../components/Loading';
import { useEmailIntegrations, useLinkedIntegrations, useResendCode } from '../../services/Integrations';
import { useUrlSingleState } from '../../services/QueryString';

function VerifyButton({ identifier, created }: { identifier: string; created: string }) {
  const navigate = useNavigate();
  const [organisation] = useUrlSingleState('organisation');
  const [canResend, setCanResend] = useState<boolean>(false);
  useEffect(() => {
    const now = new Date();
    const diff = now.getTime() - new Date(created).getTime();
    if (diff > 5 * 60000) {
      setCanResend(true);
    } else if (diff > 0) {
      setTimeout(() => setCanResend(true), 5 * 60000 - diff);
    }
  }, [created]);
  const { trigger, status } = useResendCode();
  useEffect(() => {
    if (status.succeeded) {
      navigate(`/integrations/email/verify/${identifier}`);
    }
  }, [status]);
  return (
    <DenseButton color="warning" disabled={!(canResend && !status.loading && organisation)} onClick={() => organisation && trigger({ organisation: organisation ?? '', identifier }, {})}>
      { status.loading && <Box mr={1}><Loading size={24} /></Box>}
      Resend Code
    </DenseButton>
  );
}

function IntegrationRows({ integrations, reportIntegrations }: { integrations: EmailIntegration[]; reportIntegrations: EmailLink[] }) {
  if (integrations.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={3} sx={{ textAlign: 'center' }}>No Email Integrations yet</TableCell>
      </TableRow>
    );
  }
  return (
    <>
      { integrations.map((integration, index) => {
        const cellProps: SxProps<Theme> = { textAlign: 'center', backgroundColor: t => (index % 2 === 0 ? t.palette.background.contrast : t.palette.background.paper) };
        return (
          <TableRow>
            <TableCell sx={{ ...cellProps }}><Box pl={2} display="flex" justifyContent="left" alignItems="center"><Box mr={2}><Gravatar size={24} default="mm" email={integration.email ?? ''} /></Box>{integration.email}</Box></TableCell>
            <TableCell sx={cellProps}>{moment(integration.created).fromNow()}</TableCell>
            <TableCell sx={cellProps}>{reportIntegrations.filter(it => it.integration.identifier === integration.identifier).length}</TableCell>
            <TableCell width={250} sx={cellProps}>{ integration.verified ? <Box color={t => t.palette.flat.algalFuel}><FontAwesomeIcon icon={faCheck} /></Box> : <VerifyButton identifier={integration.identifier} created={integration.created} />}</TableCell>
          </TableRow>
        );
      })}
    </>
  );
}

export default function EmailIntegrationsTable() {
  const [organisation] = useUrlSingleState('organisation');
  const linkedIntegrations = useLinkedIntegrations(organisation ?? '', 'report', 'email');
  const integrations = useEmailIntegrations(organisation ?? '').filter(it => it.organisation === organisation);
  const commonCellProps: SxProps<Theme> = { textAlign: 'center' };
  return (
    <TableContainer sx={{ borderRadius: '20px', mb: 4, pb: 2, backgroundColor: t => t.palette.background.paper }}>
      <FlexBox fontSize={24} pl={4} pt={2}>
        <Box mr={2}><FontAwesomeIcon icon={faEnvelope} /></Box>
        <Box mr={2}>Email Integrations</Box>
        <DenseButton color="primary" to="/integrations/email/create">Create</DenseButton>
      </FlexBox>
      <Table>
        { integrations.length ? (
          <TableHead>
            <TableRow>
              <TableCell><Box pl={7}>Email</Box></TableCell>
              <TableCell sx={commonCellProps}>Created Date</TableCell>
              <TableCell sx={commonCellProps}>Report Integrations</TableCell>
              <TableCell sx={commonCellProps}>Verified</TableCell>
            </TableRow>
          </TableHead>
        ) : undefined }
        <IntegrationRows integrations={integrations} reportIntegrations={linkedIntegrations as EmailLink[]} />
      </Table>
    </TableContainer>
  );
}
