import { Breadcrumbs, Typography } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import BodyContainer from '../../../components/BodyContainer';
import ContainerBody from '../../../components/BodyContainer/ContainerBody';
import ContainerTitle from '../../../components/BodyContainer/ContainerTitle';
import Crumb from '../../../components/BodyContainer/Crumb';
import OrgSelection from '../../Integrations/OrgSelection';
import ReportRunTable from './RunTable';

function ReportRuns(): JSX.Element {
  const { pathname } = useLocation();
  return (
    <BodyContainer>
      <ContainerTitle>
        <Typography variant="h1" sx={{ mr: 2 }}>Report History</Typography>
      </ContainerTitle>
      <Breadcrumbs>
        <Crumb to="/reports">Reports for <OrgSelection /></Crumb>
        <Crumb to={pathname}>Runs</Crumb>
      </Breadcrumbs>
      <ContainerBody>
        <Helmet>
          <title>Report Runs - klouds.io - Cloud Expenditure Exposed</title>
        </Helmet>
        <ReportRunTable />
      </ContainerBody>
    </BodyContainer>
  );
}

export default memo(ReportRuns, isEqual);
