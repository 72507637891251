import { Box, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import Loading from '../Loading';
import Crumb from './Crumb';

type BreakcrumbProps = { children: ReactElement<Crumb> | ReactElement<Crumb>[] };

// eslint-disable-next-line react/prefer-stateless-function
export default class Breadcrumbs extends React.Component<BreakcrumbProps> {
  render() {
    const { children } = this.props;
    const content = Array.isArray(children)
      ? children.map((child, index, arr) => (
        <React.Fragment key={`breadcrumb${index}`}>
          {child}
          {index < arr.length - 1 && (
          <Typography variant="body1" ml={1} mr={1}>
            /
          </Typography>
          )}
        </React.Fragment>
      ))
      : children;
    return (
      <Box display="flex" alignItems="center" mb={2} justifyContent={{ xs: 'center', sm: 'inherit' }}>
        <React.Suspense fallback={<Loading />}>
          {content}
        </React.Suspense>
      </Box>
    );
  }
}
