import { Box, CircularProgress, Theme } from '@material-ui/core';
import { isEqual } from 'lodash';
import React, { memo } from 'react';
import { SxProps } from '@material-ui/system';

interface LoadingProps {
  size?: number;
  alignItems?: string;
  justifyContent?: string;
  color?: string;
  sx?: SxProps<Theme>;
}

function Loading({
  size, alignItems, justifyContent, color, sx,
}: LoadingProps) {
  return (
    <Box
      sx={{
        alignItems: alignItems ?? 'center',
        justifyContent: justifyContent ?? 'center',
        flex: 1,
        height: '100%',
        display: 'flex',
        ...sx,
      }}
    >
      <CircularProgress size={size} sx={{ color }} />
    </Box>
  );
}

export default memo(Loading, isEqual);
