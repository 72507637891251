import { Typography } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import BodyContainer from '../../components/BodyContainer';
import Breadcrumbs from '../../components/BodyContainer/Breadcrumbs';
import ContainerBody from '../../components/BodyContainer/ContainerBody';
import ContainerTitle from '../../components/BodyContainer/ContainerTitle';
import Crumb from '../../components/BodyContainer/Crumb';
import { useUrlSingleState } from '../../services/QueryString';
import EmailIntegrationsTable from './EmailIntegrationsTable';
import OrgSelection from './OrgSelection';
import SlackIntegrationsTable from './SlackIntegrationsTable';

function Integrations() {
  const [organisation] = useUrlSingleState('organisation');
  return (
    <BodyContainer>
      <ContainerTitle>
        <Typography variant="h1" mr={1}>Integrations</Typography>
      </ContainerTitle>
      <Breadcrumbs>
        <Crumb to="/integrations">Integrations for <OrgSelection /></Crumb>
      </Breadcrumbs>
      <ContainerBody>
        <Helmet>
          <title>Integrations - klouds.io - Cloud Expenditure Exposed</title>
        </Helmet>
        { organisation && (
          <>
            <EmailIntegrationsTable />
            <SlackIntegrationsTable />
          </>
        )}
      </ContainerBody>
    </BodyContainer>
  );
}

export default memo(Integrations, isEqual);
