import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../ErrorFallback';
import Loading from '../Loading';

// eslint-disable-next-line react/prefer-stateless-function
export default class ContainerBody extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
      </ErrorBoundary>
    );
  }
}
